import { Application } from "@hotwired/stimulus"

import PasswordMaskToggleController from "./password_mask_toggle_controller"
import IframeResizerController from "./iframe-resizer-controller"
import HideWithSessionController from "./hide_with_session_controller"
import CalendaremailsyncReceivedController from "./calendaremailsync-received-controller"
const Stimulus = Application.start()
Stimulus.register("password-mask-toggle", PasswordMaskToggleController)
Stimulus.register("iframe-resizer", IframeResizerController)
Stimulus.register("hide-with-session", HideWithSessionController)
Stimulus.register("calendaremailsync-received", CalendaremailsyncReceivedController)

