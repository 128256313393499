import { Controller } from "@hotwired/stimulus"
import consumer from "utils/consumer"

export default class extends Controller {
  static values = { applicationId: Number }

  connect() {
    this.subscription = consumer.subscriptions.create({ channel: "CalendarEmailSyncChannel", application_id: this.applicationIdValue }, {
      received: this.received.bind(this)
    })
  }

  disconnect() {
    this.subscription?.unsubscribe()
  }

  received(data) {
    this.element.insertAdjacentHTML("beforeend", data.template)
  }
}
